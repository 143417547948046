var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { toast } from "react-toastify";
import { Router } from './router';
var fetchApi = function (route, method, query, body, stringifyBody, headers) {
    if (query === void 0) { query = {}; }
    if (body === void 0) { body = null; }
    if (stringifyBody === void 0) { stringifyBody = true; }
    if (headers === void 0) { headers = {}; }
    var url = generateRoute(route, query, false);
    var params = {
        method: method,
        headers: __assign(__assign({}, getDefaultApiHeaders()), headers),
        cache: "no-store"
    };
    if (body) {
        params['body'] = stringifyBody ? JSON.stringify(body) : body;
    }
    return fetch(url, params);
};
var generateCdnUrl = function (filename, filter) {
    return generateRoute('cdn_url', { filename: filename, filter: filter }, false);
};
var generateDownloadLink = function (filepath, query) {
    if (query === void 0) { query = {}; }
    filepath = btoa(filepath);
    query = __assign(__assign({}, query), { filepath: filepath });
    return generateRoute('download_pdf', query, false);
};
var getDefaultApiHeaders = function () {
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };
};
var getFromLocalStorage = function (key) {
    var value = localStorage.getItem(key);
    if (!value) {
        return null;
    }
    var item = JSON.parse(value);
    var now = new Date();
    if (item.expiry && now.getTime() >= item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
};
var saveInLocalStorage = function (key, value, ttl) {
    var item = { value: value };
    if (ttl) {
        var now = new Date();
        item.expiry = now.getTime() + ttl;
    }
    localStorage.setItem(key, JSON.stringify(item));
};
var showError = function (message) {
    toast.error(translate(message), {
        autoClose: false,
        theme: 'colored'
    });
};
var showSuccess = function (message, redirectUri, autoClose) {
    if (autoClose === void 0) { autoClose = false; }
    toast.success(translate(message), {
        autoClose: redirectUri || autoClose ? 1500 : false,
        onClose: redirectUri ? function () { return window.location.href = redirectUri; } : function () { },
        theme: 'colored'
    });
};
var showWarning = function (message) {
    toast.warning(translate(message), {
        autoClose: false,
        theme: 'colored'
    });
};
export var snakeCaseObjectToCamelCase = function (object) {
    Object.keys(object).forEach(function (key) {
        var value = object[key];
        delete object[key];
        var nextKey = snakeCaseToCamelCase(key);
        object[nextKey] = value;
        if (value !== null && typeof object[nextKey] === 'object') {
            snakeCaseObjectToCamelCase(object[nextKey]);
        }
    });
    return object;
};
var snakeCaseToCamelCase = function (value) {
    return value
        .split('_')
        .reduce(function (result, current, index) {
        current = index !== 0 ? current[0].toUpperCase() + current.slice(1) : current;
        return result + current;
    }, '');
};
var getLang = function () {
    return document.documentElement.lang;
};
var generateRoute = function (route, query, intl) {
    if (query === void 0) { query = {}; }
    if (intl === void 0) { intl = true; }
    if (intl) {
        route = route + "." + getLang();
    }
    return Router.generate(route, query);
};
var getFileVariant = function (filename, filter, cdn) {
    return cdn + "/" + filter + "/" + filename;
};
var translate = function (id, params, domain) {
    if (params === void 0) { params = {}; }
    if (domain === void 0) { domain = 'messages'; }
    return Translator.trans(id, params, domain);
};
export { fetchApi, generateCdnUrl, generateDownloadLink, getFromLocalStorage, saveInLocalStorage, showError, showSuccess, showWarning, getLang, generateRoute, getFileVariant, translate };
